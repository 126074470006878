<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="transparent"
    flat
    height="60"
  >
    <v-row
      v-if="$vuetify.breakpoint.width >= 1142"
      align="center"
      justify="space-between"
      no-gutters
      class="mx-2"
    >
      <v-col cols="4" :md="3" :lg="3" :xl="2">
        <v-img
          src="@/assets/PetersenLogoColor.png"
          contain
          width="300"
          height="60"
          alt="logotipo-petersen"
          @click="goTo('petersen')"
          style="cursor: pointer"
        ></v-img>
      </v-col>
      <v-col cols="5" :md="6" :lg="7" :xl="8">
        <v-tabs v-model="pos" color="title" centered>
          <v-tab
            v-for="(title, i) of titles"
            :key="i"
            @click="goTo(title.link, i)"
          >
            {{ title.name }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="3" :md="2" :lg="2" :xl="2">
        <social-buttons align="end" />
      </v-col>
    </v-row>
    <v-row align="center" justify="center" no-gutters v-else>
      <v-btn icon small @click="SET_DRAWER(true)">
        <v-icon color="title">menu</v-icon>
      </v-btn>
      <v-spacer />
      <v-img
        src="@/assets/PetersenLogoColor.png"
        contain
        max-width="260"
        width="260"
        height="60"
        alt="logotipo-petersen"
        @click="goTo('petersen')"
        style="cursor: pointer"
      ></v-img>
      <v-spacer />
    </v-row>
  </v-app-bar>
</template>

<script>
import SocialButtons from '@/components/core/socialButtons.vue';
import {mapMutations} from 'vuex';

export default {
  name: 'AppBar',
  components: {SocialButtons},
  data: () => ({
    pos: 0,
    drawer: false,
    titles: [
      {
        id: 0,
        link: 'petersen',
        name: 'Inicio',
      },
      {
        id: 1,
        link: 'compramos-tu-auto',
        name: 'Compramos tu auto',
      },
      {
        id: 2,
        link: 'vendemos-tu-auto',
        name: 'Vendemos tu auto',
      },
      {
        id: 3,
        link: 'renovacion',
        name: 'Renovación',
      },
      {
        id: 4,
        link: 'Stock',
        name: 'Stock',
      },
      {
        id: 5,
        link: 'financiamiento',
        name: 'Financiamiento',
      },

      {
        id: 6,
        link: 'testimonios',
        name: 'Testimonios',
      },
      {
        id: 7,
        link: 'mision-vision',
        name: 'Misión & visión',
      },
    ],
  }),
  methods: {
    ...mapMutations(['SET_DRAWER', 'SET_SCROLL']),
    goTo(link, i) {
      if (i === 0) this.SET_SCROLL(true);
      this.$router.push({name: link}).catch(() => {});
    },
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.name) {
          const title = this.titles.find((title) => {
            return val.path.includes(title.link.toLowerCase());
          });
          if (title) {
            this.pos = title.id;
          }
        }
      },
    },
  },
};
</script>

<style scoped></style>
