<template>
  <v-row
    align="center"
    :justify="align"
    no-gutters
    :class="margin ? 'mt-3' : ''"
  >
    <v-img
      v-for="(icon, index) of icons"
      :key="index"
      :src="icon.asset"
      contain
      :width="resizeIconWidth(margin)"
      :max-width="resizeIconWidth(margin)"
      :height="resizeIconHeight()"
      :max-height="resizeIconHeight()"
      @click="goTo(icon.link)"
      class="pointer shadow mx-1"
    ></v-img>
  </v-row>
</template>

<script>
import {resizeIconHeight, resizeIconWidth} from '@/utils/general';

export default {
  name: 'socialButtons',
  props: {
    chick: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: 'center',
    },
    margin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mdAndDown: 'width: 35px; height: 35px',
    chickMdAndDown: 'width: 30px; height: 30px',
    chickXlOnly: 'width: 35x; height: 35px',
    xlOnly: '; ',
    icons: [
      {
        asset: require('@/assets/logos/whatsapp.svg'),
        link:
          'https://wa.me/56944436444?text=Hola Petersen! Estaba viendo su página web y tengo algunas consultas',
      },
      {
        asset: require('@/assets/logos/instagram.svg'),
        link: 'http://instragram.com/automotrizpetersen',
      },
      {
        asset: require('@/assets/logos/facebook.svg'),
        link: 'https://www.facebook.com/automotrizpetersen',
      },
      {
        asset: require('@/assets/logos/map.svg'),
        link:
          'https://www.google.com/maps/search/?api=1&query=automotriz%20petersen',
      },
      {
        asset: require('@/assets/logos/phone.svg'),
        link: 'tel:+569 4443 6444',
      },
    ],
  }),
  methods: {
    goTo(link) {
      window.open(link, '_blank');
    },
    resizeIconWidth,
    resizeIconHeight,
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
